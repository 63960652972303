export const fromSearchFore = [{
    type: 'select',
    name: '商户',
    prop: 'tenantId',
    isPostApi: 'shopList',
    postURl: '',
    postData: [],
    isPlatform: 1,//平台显示，商户不显示
    props:{
        value:'id',
        label:'name'
    },
    isFartherFun: 'appId',//关联的选项
},{
    type: 'select',
    name: 'App名称',
    prop: 'appId',
    isPostApi: 'getUserappinfoList',
    postURl: '',
    postData: [],   
    props:{
        value:'appId',
        label:'appName'
    },
    isRelation:'tenantId'//目标对象
},{
    type: 'select',
    name: '链',
    prop: 'chain',
    isPostApi: 'getCoinList',
    postURl: '',
    postData: [],
    props:{
        value:'chain',
        label:'chain'
    },
    isFartherFun: 'protocol',//关联的选项
},{
    type: 'select',
    name: '协议',
    prop: 'protocol',
    isPostApi: 'getAgreementList',
    postURl: '',
    postData: [],
    props:{
        value:'protocol',
        label:'protocol'
    },
    isRelation:'chain'//目标对象
}]
export const contractAddress = [{
    type: 'input',
    name: '合约地址',
    prop: 'contractAddress',
},]
export const chainProtocol = [{
    type: 'select',
    name: '链',
    prop: 'chain',
    isPostApi: 'getCoinList',
    postURl: '',
    postData: [],
    props:{
        value:'chain',
        label:'chain'
    },
    isFartherFun: 'protocol',//关联的选项
},{
    type: 'select',
    name: '协议',
    prop: 'protocol',
    isPostApi: 'getAgreementList',
    postURl: '',
    postData: [],
    props:{
        value:'protocol',
        label:'protocol'
    },
    isRelation:'chain'//目标对象
}]